<template>
  <div class="empty-wrapper">
    服务升级中
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.empty-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
</style>